<template>
  <div class="d-flex flex-column c-pointer" @click="selectGroup">
    <div class="d-flex px-4 py-2">
      <div class="mr-4 d-flex align-center justify-center">
        <v-chip
          v-if="tasks.some((x) => x.status === 'open')"
          :color="
            getDaysLeft(task) < 0
              ? 'error'
              : getDaysLeft(task) === 0
                ? 'info'
                : 'success'
          "
          small
        >
          {{ getDaysLeft(task) }}
        </v-chip>
        <v-icon v-else color="success"> mdi-check-circle</v-icon>
      </div>

      <div class="d-flex flex-column">
        <div class="fs-20 font-weight-bold d-flex align-center">
          {{ task.task_type.custom_1 }}
          <v-chip class="mx-2" small>{{ tasks.length }}x</v-chip>
          <div
            v-for="(item, index) in tasks"
            :key="item.id"
            class="fs-16 font-italic font-weight-regular d-flex"
          >
            <v-divider v-if="index > 0" class="mx-2" vertical />
            {{ item.task_type.custom_2 }}
          </div>
        </div>
        <div>{{ task.description }}</div>
      </div>

      <v-spacer />

      <div class="d-flex flex-column px-2">
        <div class="d-flex align-center">
          <v-icon class="mr-2">
            {{
              getHallOrLocation(task.task_type.custom_3).hall
                ? 'mdi-warehouse'
                : 'mdi-file-table-box'
            }}
          </v-icon>
          {{
            getHallOrLocation(task.task_type.custom_3).hall
              ? getHallOrLocation(task.task_type.custom_3).hall
              : getHallOrLocation(task.task_type.custom_3).name
          }}
        </div>
        <div class="ml-2 d-flex align-center">
          <v-icon>mdi-arrow-right-bottom</v-icon>
          <v-icon class="mr-2">
            {{
              getHallOrLocation(task.task_type.custom_4).hall
                ? 'mdi-warehouse'
                : 'mdi-file-table-box'
            }}
          </v-icon>
          {{
            getHallOrLocation(task.task_type.custom_4).hall
              ? getHallOrLocation(task.task_type.custom_4).hall
              : getHallOrLocation(task.task_type.custom_4).name
          }}
        </div>
      </div>
    </div>
    <Transition name="pick-order-extension">
      <div v-if="isSelected" class="d-flex flex-column overflow-hidden pos-rel">
        <v-divider />
        <div
          v-if="tasks.some((x) => x.task_type.custom_5 === null)"
          class="pick-up-task-overlay"
        >
          <v-btn color="primary" elevation="0" @click="pickupTask"
            >Pick up
          </v-btn>
        </div>
        <v-tabs v-model="taskTabs" background-color="transparent" height="30px">
          <v-tab
            v-for="tabTask in tasks"
            :key="tabTask.id"
            :disabled="!dhmeYardManagementSelectedTask.task_type.custom_5"
          >
            <v-icon
              v-if="tabTask.status === 'closed'"
              class="mr-1"
              color="success"
              small
              >mdi-check-circle
            </v-icon>
            {{ tabTask.task_type.custom_2 }}
          </v-tab>
        </v-tabs>
        <div
          :class="{
            'pick-up-task-actions-inactive':
              !dhmeYardManagementSelectedTask.task_type.custom_5,
          }"
          class="d-flex flex-column px-10 py-3 fs-12"
        >
          <div class="d-flex align-center my-1">
            Picked up task
            <v-icon
              v-if="dhmeYardManagementSelectedTask.task_type.custom_5"
              class="ml-2"
              color="success"
              small
              >mdi-check-circle-outline
            </v-icon>
            <v-spacer />
            <ant-unix-to-local-time
              v-if="dhmeYardManagementSelectedTask.task_type.custom_5"
              :timestamp="dhmeYardManagementSelectedTask.task_type.custom_5"
              class="fs-10 font-italic"
            />
            <div v-else>-</div>
          </div>
          <div class="d-flex align-center my-1">
            <v-icon class="mr-1" dense>mdi-arrow-right-bottom</v-icon>
            <v-icon class="mr-2" dense>
              {{
                getHallOrLocation(task.task_type.custom_3).hall
                  ? 'mdi-warehouse'
                  : 'mdi-file-table-box'
              }}
            </v-icon>
            {{
              getHallOrLocation(task.task_type.custom_3).hall
                ? getHallOrLocation(task.task_type.custom_3).hall
                : getHallOrLocation(task.task_type.custom_3).name
            }}
          </div>
          <div class="d-flex align-center my-1 ml-5">
            <v-icon class="mr-1" dense>mdi-arrow-right-bottom</v-icon>
            <v-btn
              :disabled="
                dhmeYardManagementSelectedTask.task_type.custom_5 === null ||
                dhmeYardManagementSelectedTask.task_type.custom_6 !== null
              "
              elevation="0"
              small
              @click="validateElement"
            >
              <v-icon class="mr-2" small>mdi-qrcode-scan</v-icon>
              Scan element
            </v-btn>
            <v-icon
              v-if="dhmeYardManagementSelectedTask.task_type.custom_6"
              class="ml-2"
              color="success"
              small
              >mdi-check-circle-outline
            </v-icon>
            <v-spacer />
            <ant-unix-to-local-time
              v-if="dhmeYardManagementSelectedTask.task_type.custom_6"
              :timestamp="dhmeYardManagementSelectedTask.task_type.custom_6"
              class="fs-10 font-italic"
            />
            <div v-else>-</div>
          </div>
          <div class="d-flex align-center my-1 ml-10">
            <v-icon class="mr-1" dense>mdi-arrow-right-bottom</v-icon>
            <v-icon class="mr-2" dense>
              {{
                getHallOrLocation(task.task_type.custom_4).hall
                  ? 'mdi-warehouse'
                  : 'mdi-file-table-box'
              }}
            </v-icon>
            {{
              getHallOrLocation(task.task_type.custom_4).hall
                ? getHallOrLocation(task.task_type.custom_4).hall
                : getHallOrLocation(task.task_type.custom_4).name
            }}
          </div>
          <div class="d-flex align-center my-1 ml-15">
            <v-icon class="mr-1" dense>mdi-arrow-right-bottom</v-icon>
            <v-btn
              :disabled="
                dhmeYardManagementSelectedTask.task_type.custom_6 === null ||
                dhmeYardManagementSelectedTask.task_type.custom_7 !== null
              "
              elevation="0"
              small
              @click="storeElement"
            >
              <v-icon class="mr-2" small>mdi-checkbox-outline</v-icon>
              Store element
            </v-btn>
            <v-icon
              v-if="dhmeYardManagementSelectedTask.task_type.custom_7"
              class="ml-2"
              color="success"
              small
              >mdi-check-circle-outline
            </v-icon>
            <v-spacer />
            <ant-unix-to-local-time
              v-if="dhmeYardManagementSelectedTask.task_type.custom_7"
              :timestamp="dhmeYardManagementSelectedTask.task_type.custom_7"
              class="fs-10 font-italic"
            />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import AntUnixToLocalTime from '@/components/AntUnixToLocalTime.vue';

export default {
  name: 'YardManagementItem',
  components: { AntUnixToLocalTime },
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    halls: {
      type: Array,
      default: () => [],
    },
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      taskTabs: 0,
    };
  },
  computed: {
    ...mapGetters([
      'dhmeYardManagementSelectedGroup',
      'dhmeYardManagementSelectedTask',
    ]),

    isSelected() {
      return (
        this.dhmeYardManagementSelectedGroup
          .map((task) => task.id)
          .join('-') === this.tasks.map((task) => task.id).join('-')
      );
    },

    task() {
      return this.tasks[0];
    },
  },
  watch: {
    taskTabs(value) {
      if (this.isSelected) {
        this.$store.commit(
          'module_yard_management_select_group_task',
          this.tasks[value]
        );
      }
    },
  },
  methods: {
    selectGroup() {
      if (!this.isSelected) {
        this.$store.commit('module_yard_management_select_group', this.tasks);
        this.$store.commit(
          'module_yard_management_select_group_task',
          this.tasks[0]
        );
      }
    },
    getHallOrLocation(id) {
      let item = this.halls.find((item) => item.id === id);
      if (item) {
        return item;
      }
      return this.locations.find((item) => item.id === id);
    },
    getDaysLeft(task) {
      return moment(task.due).diff(this.now, 'days');
    },
    pickupTask() {
      this.$store.dispatch('pickupDhmeYardManagementGroup');
    },
    storeElement() {
      this.$emit('storeElement');
    },
    validateElement() {
      this.$emit('validateElement');
    },
  },
};
</script>

<style lang="scss" scoped>
.pick-up-task-actions-inactive {
  opacity: 0.5;
  pointer-events: none;
}

.pick-up-task-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.pick-order-extension-enter,
.pick-order-extension-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.pick-order-extension-enter-active {
  transition:
    transform 400ms 400ms,
    opacity 200ms 600ms;
}

.pick-order-extension-enter-to,
.pick-order-extension-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>
